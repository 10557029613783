<template>
  <div class="analysis-generate" v-loading.fullscreen.lock="loading" :element-loading-text="$t('common.preparingData')">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="180px" size="small">
      <el-form-item class="search-form-gutter" :label="$t('analysis.platform')" required>
        <el-select style="width: 88px" v-model="currentPlatform" @change="changePlatform" filterable>
          <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="analysisType.name === 'Best10' || analysisType.name === 'Matrix'" :label="$t('analysis.date')"
        key="date" prop="date" required>
        <el-select class="date-select" v-model="form.date" clearable :multiple="analysisType.name === 'Matrix'">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="analysisType.name === 'Best10' || analysisType.name === 'Matrix'"
        :label="$t('analysis.compareDate')" key="compareDate" prop="compareDate">
        <el-select class="date-select" v-model="form.compareDate" clearable :multiple="analysisType.name === 'Matrix'">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="analysisType.name === 'TPS'" :label="$t('analysis.group') + '1'" key="previousDates" required>
        <el-form-item class="inline-form-item" prop="previousGroupFristDates">
          <el-select class="date-select" v-model="form.previousGroupFristDates" clearable multiple>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <label class="date-vs">VS</label>
        <el-form-item class="inline-form-item" prop="previousGroupSecondDates">
          <el-select class="date-select" v-model="form.previousGroupSecondDates" clearable multiple>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="analysisType.name === 'TPS'" :label="$t('analysis.group') + '2'" key="currentDates">
        <el-form-item class="inline-form-item" prop="currentGroupFristDates">
          <el-select class="date-select" v-model="form.currentGroupFristDates" clearable multiple>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <label class="date-vs">VS</label>
        <el-form-item class="inline-form-item" prop="currentGroupSecondDates">
          <el-select class="date-select" v-model="form.currentGroupSecondDates" clearable multiple>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="disableSubmit" @click="onSubmit">{{
          $t("common.submit")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  fetchRecords,
  fetchBest10Reports,
  fetchTPSReports,
  fetchMatrixReports,
  exportBest10Reports,
  exportTPSReports,
  exportMatrixReports,
} from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { ossRoot } from "@/utils/server";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    let isBest10 = false;
    if (this.$route.meta && this.$route.meta.key === "best20-report") {
      isBest10 = true;
    }
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      form: {
        date: isBest10 ? "" : [],
        compareDate: isBest10 ? "" : [],
        previousGroupFristDates: [],
        previousGroupSecondDates: [],
        currentGroupFristDates: [],
        currentGroupSecondDates: [],
      },
      rules: {
        date: [{ required: true, trigger: "change" }],
        previousGroupFristDates: [
          {
            required: true,
            trigger: "change",
          },
        ],
        previousGroupSecondDates: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      options: [],
      timer: null,
      disableSubmit: false,
      loading: false,
    };
  },
  created() {
    this.fetchOptions();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    fetchOptions() {
      this.options = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.options.push({
                value: e.date,
                label: e.linkDate,
              });
            });
        }
      });
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const type = this.analysisType.name;
        this.checkExist(type, (url) => {
          if (url) {
            this.showDownload(type, url, true);
          } else {
            this.exported(type);
          }
        });
      });
    },
    checkExist(type, cb) {
      this.disableSubmit = true;
      let params = { limit: 1, period: "week" };
      if (type === "Best10") {
        params.date = this.form.date;
        params.compareDate = this.form.compareDate;
        fetchBest10Reports(params)
          .then((response) => {
            if (response.success) {
              if (cb) {
                cb(
                  response.result.items && response.result.items.length > 0
                    ? response.result.items[0].url
                    : null
                );
              }
            }
          })
          .finally(() => {
            this.disableSubmit = false;
          });
      } else if (type === "Matrix") {
        params.date = this.form.date.join(",");
        params.compareDate = this.form.compareDate.join(",");
        fetchMatrixReports(params)
          .then((response) => {
            if (response.success) {
              if (cb) {
                cb(
                  response.result.items && response.result.items.length > 0
                    ? response.result.items[0].url
                    : null
                );
              }
            }
          })
          .finally(() => {
            this.disableSubmit = false;
          });
      } else {
        params.previousGroupFristDates =
          this.form.previousGroupFristDates.join(",");
        params.previousGroupSecondDates =
          this.form.previousGroupSecondDates.join(",");
        params.currentGroupFristDates =
          this.form.currentGroupFristDates.join(",");
        params.currentGroupSecondDates =
          this.form.currentGroupSecondDates.join(",");
        fetchTPSReports(params)
          .then((response) => {
            if (response.success) {
              if (cb) {
                cb(
                  response.result.items && response.result.items.length > 0
                    ? response.result.items[0].url
                    : null
                );
              }
            }
          })
          .finally(() => {
            this.disableSubmit = false;
          });
      }
    },
    exported(type) {
      let params;
      if (type === "Best10") {
        params = {
          period: "week",
          date: this.form.date,
          compareDate: this.form.compareDate,
        };
      } else if (type === "Matrix") {
        params = {
          period: "week",
          date: this.form.date.join(","),
          compareDate: this.form.compareDate.join(","),
        };
      } else {
        params = {
          period: "week",
          platform: this.currentPlatform,
          previousGroupFristDates: this.form.previousGroupFristDates.join(","),
          previousGroupSecondDates:
            this.form.previousGroupSecondDates.join(","),
          currentGroupFristDates: this.form.currentGroupFristDates
            ? this.form.currentGroupFristDates.join(",")
            : null,
          currentGroupSecondDates: this.form.currentGroupSecondDates
            ? this.form.currentGroupSecondDates.join(",")
            : null,
        };
      }

      this.loading = true;
      let cb = (response) => {
        if (response.success) {
          this.timer = setTimeout(() => {
            this.fetchUrl(type);
          }, this.delay(type));
        }
      };
      if (type === "Best10") {
        exportBest10Reports(params).then((response) => {
          cb(response);
        });
      } else if (type === "Matrix") {
        exportMatrixReports(params).then((response) => {
          cb(response);
        });
      } else {
        exportTPSReports(params).then((response) => {
          cb(response);
        });
      }
    },
    fetchUrl(type) {
      this.checkExist(type, (url) => {
        if (url) {
          this.$refs.form.resetFields();
          clearTimeout(this.timer);
          this.loading = false;
          this.showDownload(type, url);
        } else {
          this.timer = setTimeout(() => {
            this.fetchUrl(type);
          }, this.delay(type));
        }
      });
    },
    showDownload(type, url, exist) {
      const h = this.$createElement;
      this.$msgbox({
        title: this.$t("common.prompt"),
        message: h("div", null, [
          h("i", { class: "el-icon-success" }),
          h(
            "span",
            { style: "margin-left: 10px" },
            exist
              ? this.$t("analysis.reportExist")
              : this.$t("analysis.generateSucc", { type })
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t("common.download"),
        cancelButtonText: exist
          ? this.$t("common.regenerate")
          : this.$t("common.cancel"),
        distinguishCancelAndClose: true,
      })
        .then((action) => {
          if (action === "confirm") {
            var a = document.createElement("a");
            a.href = ossRoot + "/" + url;
            a.download = "ai-analysis-report" + Date.now() + ".xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
          }
        })
        .catch((action) => {
          if (action === "cancel" && exist) {
            this.exported(type);
          }
        });
    },
    delay(type) {
      if (type === "Best10" || type === "Matrix") {
        return 4000;
      } else {
        return 2000;
      }
    },
    changePlatform() {
      this.fetchOptions();
    },
  },
  computed: {
    analysisType() {
      if (this.$route.meta && this.$route.meta.key === "tps") {
        return { name: "TPS" };
      } else if (
        this.$route.meta &&
        this.$route.meta.key === "matrix"
      ) {
        return { name: "Matrix" };
      }
      return { name: "Best10" };
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-generate {
  .form {
    margin-top: 25px;
  }

  .date-vs {
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    margin-left: 10px;
    margin-right: 10px;
  }

  .date-select {
    width: 200px;
  }

  .inline-form-item {
    display: inline-block;
    margin-bottom: 0;
  }
}
</style>